"use client";

import type { ReactNode } from "react";
import {
    AssistantRuntimeProvider,
    useLocalRuntime,
    type ChatModelAdapter,
} from "@assistant-ui/react";
import autoFaqApi from '../../../apps/dashboard/src/services/autoFaqApi';
import { useState } from 'react';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import apiConfig from '../../../apps/dashboard/src/services/config/apiConfig';
import { getAuth } from 'firebase/auth';

const MyModelAdapter: ChatModelAdapter = {

    async run({ messages, abortSignal }) {
        let test = messages;

        let length = messages.length;

        console.log('test: ', test);
        console.log('length: ', length);

        let note = messages[messages.length - 1].content[0].text;
        console.log('note: ', note);


        if (note == 'Tell me what Hypertype can help me with?') {
            note = `Send this back: Write faster by crafting responses and outreach messages in an instant with one-click drafting in Gmail, Intercom, 
                  Zendesk or Outlook. Connect Hypertype to your data sources, and watch it extract relevant information from PDFs, Excel files, or live data from websites in seconds.
                  Write in your unique voice by syncing your email history with Hypertype to capture your personal tone, or establish a consistent company voice that resonates across your team.
                  Build a real-time knowledge base and effortlessly search for answers from any integrated company data source anytime, anywhere. Integrate your data with just one click—no technical expertise required!
                  Supercharge Your Productivity Today!`;
        }

        if (note == 'Sync with Hypertype integrations') {
            note = `Reply this way only and do not add anything else: Integrate with Gmail, Outlook, Intercom and Zendesk`;
        }

        let suggestedReply = '';

        const payload = {
            "replying_to": "\n",
            "tone": "write as a statement",
            "language": "auto",
            "formality": "light and casual tone",
            "trace_id": 937251667,
            "sender": "Hypertype Chat",
            "updated_sender": "Hypertype Chat",
            "notes": [
                note
            ],
            "goal": "",
            "outreach": false,
            "return_sources": true
        };

        console.log('Request payload:', JSON.stringify(payload));
        const token = await getAuth().currentUser.getIdToken(true);
        await fetchEventSource(`${apiConfig.getGeneratedText}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                Authorization: `Bearer ${token}`,
                token: `Bearer ${token}`
            },
            body: JSON.stringify(payload),
            signal: abortSignal,
            onopen: (response) => {
                if (!response.ok) {
                    throw new Error(`Failed to open stream, status: ${response.status}`);
                }
                console.log('Connection opened:', response.status);
            },
            onmessage: (event) => {
                if (event.event === 'status') {
                    const endTime = Date.now();
                }

                if (event.event === 'content') {
                    try {
                        console.log('Content: ', event);
                        const content = JSON.parse(event.data).message;
                        suggestedReply += content;
                    }
                    catch (e) {
                        console.error('Error parsing content', e);
                        // sentryScope.captureException(e);
                    }
                }

                if (event.event === 'sources') {
                    try {
                        const sources = JSON.parse(event.data).sources;
                        // setSources(sources);
                    }
                    catch (e) {
                        console.error('Error parsing content', e);
                        // sentryScope.captureException(e);
                    }
                }
            },
            onclose: () => {
                console.log('Stream closed.');
            },
            onerror: (error) => {
                console.error('Stream error:', error);
                throw error; // Stop further processing
            },
        });


        return {
            content: [
                {
                    type: "text",
                    text: suggestedReply,
                },
            ],
        };


    },
};




export function MyRuntimeProvider({
    children,
}: Readonly<{
    children: ReactNode;
}>) {
    const runtime = useLocalRuntime(MyModelAdapter);

    return (
        <AssistantRuntimeProvider runtime={runtime}>
            {children}
        </AssistantRuntimeProvider>
    );
}