/* eslint-disable import/no-import-module-exports */
import Hotjar from '@hotjar/browser';
import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import * as Sentry from "@sentry/react";

import App from './App';
import './index.css';

const hotjarArgs = {
  siteId: 3637655,
  hotjarVersion: 6,
};

const tagManagerArgs = {
  gtmId: 'GTM-KQ3NF74',
};


Sentry.init({
  dsn: "https://b84f663ac9f5ed59db87e959588593c8@o4506803623952384.ingest.sentry.io/4506803625394176",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

TagManager.initialize(tagManagerArgs);
Hotjar.init(hotjarArgs.siteId, hotjarArgs.hotjarVersion);

const root = createRoot(document.getElementById('app'));
root.render(<App />);
localStorage.setItem('appTempId', uuidv4());

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

window.getAppTempId = function () {
  alert(`app temp Id : ${localStorage.getItem('appTempId')}`);
};
